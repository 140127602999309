const i18n = require("../../config/i18n")

const linkResolver = ({ node, key, value }) => doc => {
  if (doc.lang !== undefined) {
    const prefix = i18n[doc.lang].default ? `/` : `/${i18n[doc.lang].path}/`
    return `${prefix}${doc.uid}`
  } else {
    return `/${doc.uid}`
  }
}

module.exports = linkResolver
